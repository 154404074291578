@import "../../assets/styles/variables";

.navigation {
    ul {
        list-style-type: none;
    }

    background: rgb(242, 245, 4);
    font-size: 12px;
    padding-bottom: 3rem;

    @include breakpoint(desktop) {
        font-size: 1rem;
        padding-bottom: 0;
    }

    .container {
        padding: 4rem 1rem;
        padding-bottom: 1rem;

        .linkStatic {
            color: rgba(var(--blk), 0.7);
            font-weight: bold;

            &:hover {
                color: black;
            }
        }

        >.row {
            justify-content: center;
            width: 100%;
            margin-right: 0;
            margin-left: 0;

            @include breakpoint(laptop) {
                flex-wrap: nowrap;
            }

            .navLeft {
                .items {
                    flex-wrap: nowrap;
                }

                .logo {
                    width: 100%;
                }

                .itemLink {
                    white-space: nowrap;
                    font-weight: 900;

                    &.gray {
                        color: rgba(90, 90, 90, 0.5);
                    }
                }

                .left-desc {
                    font-weight: bold;
                    letter-spacing: .03rem;
                    letter-spacing: .03rem;
                }

                @include breakpoint(tablet) {
                    padding-right: 2rem;

                    .itemLink {
                        font-size: 15px;
                    }

                    .left-desc {
                        font-size: 1.1rem;
                    }
                }
            }

            .navRight {

                .navRightWrap {
                    flex-wrap: wrap;

                    @include breakpoint(laptop) {
                        flex-wrap: nowrap;
                    }
                }

                .imageRight {
                    margin-left: 1rem;
                    margin-top: 1rem;

                    img {
                        width: 140px;
                    }
                }

                .textNav {
                    font-size: small;
                    font-weight: bold;
                    margin-top: 3rem;

                    @include breakpoint(laptop) {
                        margin-top: -1rem;
                    }
                }

                .dashed {
                    border-top: 0.1rem dashed rgba($color: #000000, $alpha: 0.7);
                }

                @include breakpoint(laptop) {
                    padding-top: 0;
                }

                .textSkew {
                    transform: skew(-10deg, 0deg) rotateZ(-15deg);
                    margin-top: -4rem !important;
                    margin-left: -1rem !important;
                    margin-bottom: 3.5rem !important;
                    font-size: 12px;
                    line-height: 1.7;
                    font-weight: 900;

                    @include breakpoint(untilTablet) {
                        margin-bottom: 2rem !important;
                    }
                }

                .linkRight {
                    @include breakpoint(untilTablet) {
                        justify-content: end;
                    }
                }

                .navImages {
                    position: relative;

                    .topRight {
                        position: absolute;
                        background-color: rgba($color: white, $alpha: 0.5);
                        border-left: 0.8rem solid rgba(242, 245, 4, 0.8);
                        border-bottom: 0.8rem solid rgba(242, 245, 4, 0.8);
                        top: 0;
                        right: 0;
                        padding: 1.5rem;
                        border-bottom-left-radius: 1.5rem;
                        border-top-right-radius: 1.5rem;

                        @include breakpoint(untilTablet) {
                            padding: 12px;
                            width: 70%;

                            h1,
                            p {
                                text-align: end;
                            }
                        }

                        h1,
                        p {
                            font-size: 12px;
                            font-weight: bold;
                            margin-bottom: 0;
                        }

                        h1 {
                            font-size: 14px;
                        }

                        h1 {
                            span {
                                font-size: 18px;
                            }
                        }
                    }

                    .image {
                        img {
                            border-radius: 1.5rem;
                        }

                        @include breakpoint(laptop) {
                            img {
                                width: 412px !important;
                                height: 522px !important;
                            }
                        }

                        @include breakpoint(desktop) {
                            img {
                                width: 451px !important;
                                height: 571px !important;
                            }
                        }
                    }
                }

                .image2 {
                    img {
                        width: 100%;
                        height: auto;
                    }

                    @include breakpoint(laptop) {
                        img {
                            width: 140px;
                        }
                    }
                }

                .textDesc {
                    font-size: 12.4px;
                    font-weight: bold;
                    color: #333;
                    font-family: 'Hiragino Sans Old W8 JIS2004';

                    div {
                        margin-top: 0.5rem;
                    }
                }

                .linkIcon {
                    font-size: 20px;
                }

                .linkRight {
                    color: #333;
                    font-weight: bold;
                    font-size: 12.4px;
                }
            }
        }
    }
}