@import "../../assets/styles/variables";

.footer {
  padding: 0 1.5rem;
  padding-top: 12vh;

  > .row {
    justify-content: center;
    align-items: center;

    .socialShare {
      margin-top: 3rem;

      @include breakpoint(laptop) {
        margin-top: 5rem;
      }
    }

    .socialIcon {
      margin: 0 0.5rem;
      font-size: 24px;
      color: rgba($color: #000000, $alpha: 0.7);
      transition: all 0.2s ease;

      &.twitter {
        &:hover {
          transition: all 0.2s ease;
          color: #1da1f2;
        }
      }

      &.facebook {
        &:hover {
          transition: all 0.2s ease;
          color: #4267b2;
        }
      }
    }

    .bottomFooter {
      padding-top: 10vh;
    }

    @include breakpoint(laptop) {
      .footerLogo {
        img {
          width: 280px;
        }
      }

      .bottomFooter {
        padding-top: 0;
      }
    }

    .topRight {
      top: 0;
      right: 0;
      text-align: center;
      padding: 12px;
      border-bottom-left-radius: 0.8rem;
      background-color: rgba($color: #fff, $alpha: 0.5);
      font-size: 13px;
      border-left: 0.8rem solid white;
      border-bottom: 0.8rem solid white;
      font-weight: bold;
      position: relative;
      width: 10rem;

      @include breakpoint(laptop) {
        padding: 30px;
        width: 195px;

        div,
        p {
          font-size: 15px;
        }
      }

      div,
      p {
        z-index: 3;
        position: relative;
      }

      &:hover,
      &:focus {
        color: black;
        &:before {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }

      &:before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        inset: -0.1rem 0 0.2em 0;
        background: rgba(242, 245, 4, 0.9);
        z-index: 0;
        transition: transform 0.3s ease;
        transform: scaleX(0);
        transform-origin: bottom right;
        border-top-right-radius: 1.25rem;
      }

      p {
        margin-bottom: 0;
      }
    }

    .footerImage {
      img {
        width: 100%;
        border-radius: 1rem;
      }
      @include breakpoint(laptop) {
        img {
          width: 412px;
          height: 522px;
        }
      }
      @include breakpoint(desktop) {
        img {
          width: 451px;
          height: 571px;
        }
      }
    }
  }

  .copyright {
    text-align: center;
    margin-top: 2rem;
    padding-bottom: 2rem;
    font-size: 8px;
    font-weight: bold;
  }
}
